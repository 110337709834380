<template>
<!-- Composant utilisé dans la vue des activités et celles des rendements -->
  <div>
    <!-- Unités de rendement -->
    <b-row class="mb-2">
      <b-col>
        <b-select size="sm" :options="units" v-model="unitSelected" value-field="id" text-field="unit" :required="required"></b-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      unitSelected: "",
      loadComponent: false
    };
  },
  props: {
    activity_yield: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Dropdown,
  },
  async mounted() {
    // En mode modification, on récupère l'unité courante
    this.loadComponent = false;
    this.unitSelected = this.units[0].id;
    if (this.$route.name === "yield.edit") {
      this.unitSelected = this.currentEntry.unit;
    }
    if (this.$route.name === "activity.edit") {
      this.unitSelected = this.activity_yield?.unit;
    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  
  computed: {
    ...mapGetters({
      units: "doseUnitsFiltered",
    }),
  },
  watch: {
    unitSelected: {
      handler: async function () {
        if (this.$route.name.includes("activity")){
          await this.$store.dispatch(this.storeEditAction, { theYield_unit: this.unitSelected });
        }else {
          await this.$store.dispatch(this.storeEditAction, { unit: this.unitSelected });
        }
      },
    },
  },
};
</script>

<style></style>
