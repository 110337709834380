<template>
<!-- composant d'édition du rendement d'une activité de récolte -->
	<div>
		<hr/>
		<h4 class="subtitle-form">{{$t("activities.details.harvest")}}</h4>
		<div class="text-infos bg-white mb-2">
			<b-row>
				<b-col cols="4" class="text-activity-notes subtitle-params pt-1 pr-0 text-right">
					{{ $t("activities.details.yield") }}
				</b-col>
				<!-- rendement -->
				<b-col cols="4" class="pr-1">
					<EditYield :activity_yield="activity_yield" :required="false"
											:storeEditAction="storeEditAction"
											:storeReadGetter="storeReadGetter"
											:storeEditedGetter="storeEditedGetter"/>
				</b-col>
				<!-- Unité -->
				<b-col cols="4" class="pl-0">
					<EditUnitYield :activity_yield="activity_yield" :required="false"
													:storeEditAction="storeEditAction"
													:storeReadGetter="storeReadGetter"
													:storeEditedGetter="storeEditedGetter"/>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="4" class="text-activity-notes subtitle-params pt-1 pr-0 text-right">
					{{ $t("harvestStorage.list.product") }}
				</b-col>
				<!-- Variété -->
				<b-col cols="8">
					<ProductSelect :activity_yield="activity_yield" 
													:storeEditAction="storeEditAction"
													:storeReadGetter="storeReadGetter"
													:storeEditedGetter="storeEditedGetter"/>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import EditYield from "@/components/yield/edit/EditYield.vue";
import EditUnitYield from "@/components/yield/edit/EditUnitYield.vue";
import ProductSelect from "@/components/harvestStorage/edit/ProductSelect.vue";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";

export default {
	mixins: [DetailUpdateMixin, LoadFormPartMixin],

  components: {
    EditYield,
    EditUnitYield,
    ProductSelect,
  },
  props: {
    activity_yield: {
      type: Object,
      default: () => {},
    },
  },
	methods: {
		validateForm: function () {
      this.isValid = true;
      return true;
		}
	}
};
</script>
<style lang="scss" scoped>
</style>