<template>
<!-- Composant utilisé dans la vue des activités et celles des rendements -->
  <div>
    <!-- Rendement -->
    <b-row class="mb-2">
      <b-col>
        <NumericInput
                id="yield"
                inputRef="input"
                :value="parseFloat(theYield)"
                @input="i => {theYield = i}"
                :required=true
                :numberOfDigits="2"
          />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      theYield: "",
      loadComponent: false,
      isOnHarvest: null
    };
  },
  props: {
    activity_yield: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    this.loadComponent = false;
    // En mode modification, on récupère le rendement courant
    if (this.$route.name === "yield.edit") {
      this.theYield = this.currentEntry.theYield;
    }
    if (this.$route.name === "activity.edit") {
      this.theYield = this.activity_yield?.theYield;
    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  watch: {
    theYield: {
      handler: async function () {
        await this.$store.dispatch(this.storeEditAction, { theYield: this.theYield != "" ? parseFloat(this.theYield) : undefined });
      },
    }
  },
  components: {
    NumericInput
  },
};
</script>

<style></style>
