<template>
<!-- Ce composant est utilisé dans l'édition des récoltes, des rendements, des activités de transport et de récolte -->
  <div v-if="this.products.length > 1">
    <!-- Variété -->
    <h4 v-if="isOnHarvest || isOnYield" class="subtitle-form">{{ $t("harvestStorage.list.product") }}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select mb-2"
      label="name"
      :options="products"
      :value="this.productSelected"
      @input="(selectedInput) => checkProduct(selectedInput)"
      :required="isOnHarvest"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import { i18n } from "@/i18n.js";
import ProductsModule from "@/store/modules/products";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      productSelected: {},
      products: [],
      productsTemp: [],
      cropField: "",
      crop: "",
      crop_species_code: "",
      index: "",
      loadComponent: false,
      isOnHarvest: null,
      isOnYield: null,
      timeOutId: null,
      doneDate: null,
    };
  },
  props: {
    activity_yield: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    Dropdown,
  },
  async beforeCreated() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["products"]) {
        store.registerModule("products", ProductsModule);
      }
    }
  },

  async mounted () {
    this.loadComponent = false;
    this.isOnYield = this.$route.name.includes('yield');
    this.isOnHarvest = this.$route.name.includes('harvestStorage')
    // Si on édite une activité de transport, on enregistre le créateur, la parcelle, la culture et la date
    // pour éventuellement créer une récolte
    if(this.$route.name.includes("activity")) {
      // Enregistre le créateur
      await this.$store.dispatch("harvestStorage/setEditedEntryValue", {creator: this.user.username});
      // Récupère la parcelle
      this.cropField = this.currentActivity.cropField !== undefined? this.currentActivity.cropField : this.currentEditedActivity.cropField;
      await this.$store.dispatch("harvestStorage/setEditedEntryValue", { cropField: this.cropField });
      if (this.cropField){
        // Récupère la culture
        let cropField_obj = await this.$store.dispatch("cropfield/getOneCropfield", {id: this.cropField});
        this.crop = cropField_obj.crop;
        this.crop_species_code = cropField_obj.crop_species_code;
        await this.$store.dispatch("harvestStorage/setEditedEntryValue", { crop: this.crop });
      }
      await this.loadProductOptions();
      // récupère la date de début d'activité et l'affecte à la date de récolte
      if (Object.keys(this.currentActivity).length !== 0)
        await this.$store.dispatch("harvestStorage/setEditedEntryValue", { doneDate: this.currentActivity.startDate });

    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * @description stocke la variété sélectionnée dans l'attribut productSelected
     */
    checkProduct(value) {
      this.productSelected = value;
    },

    /**
     * @description verifie si un product est déjà présent dans le tableau des produits à séléctionner
     */
    isAlreadyAdded(id) {
      let response = "";
      this.products.map(element => {
        if(element.id == id) {
          response = id;
        }
      });
      if(response)
        return true;
      else
        return false;
    },
    async loadProductOptions() {
      this.products = [];
      this.productSelected = {};
      if(this.cropField && this.crop) {
        this.products.push({
          id: "group1",
          name: i18n.t("harvestStorage.edit.sown_varieties"),
          group: true
        });
        // on récupère les variétés présentes sur une parcelle
        try {
          this.productsTemp = this.cropField? JSON.parse(
            JSON.stringify(
              await this.$store.dispatch("cropfield/getProductsByCropfield", { id: this.cropField}),
            ),
          ) : [];
          this.productsTemp.map(product => {
            this.products.push({...product, id: product.variety, name: product.variety_name});
          });
        }catch(error) {
          console.error(error);
        }

        // s'il n'y a aucune variété, le tableau est vidé
        if (this.products.length === 1) {
          this.products = [];
        }
        // on récupère toutes les variétés de la culture présente sur cette parcelle
        this.products.push({
          id: "group2",
          name: i18n.t("harvestStorage.edit.all_varieties"),
          group: true
        });
        this.productsTemp = this.crop_species_code ? JSON.parse(
          JSON.stringify(
            await this.$store.dispatch("getAllVarieties", {
              species__code: this.crop_species_code
            }),
          ),
        ) : [];
        this.productsTemp.map(product => {
          if(!this.isAlreadyAdded(product.id))
            this.products.push(product);
        });

      }
      // Si on modifie un objet déjà existant, on initialise le sélecteur de variété
      if (["harvestStorage.edit", "yield.edit"].includes(this.$route.name)) {
        this.index = this.products.findIndex((element) => element.id == this.currentEntry.variety);
        if (this.index != -1) this.productSelected = this.products[this.index];
      }
      if (this.$route.name === "activity.edit") {
        // Pour les activités de transport, la variété vient de la récolte associée
        if (this.currentEditedActivity.activityType_code == "trans" && this.harvests.length > 0) {
          this.index = this.products.findIndex((element) => element.id == this.harvests[0].variety);
          if (this.index != -1) this.productSelected = this.products[this.index];
        }
        // Pour les activité de récolte, la variété vient du rendement associé
        if (this.currentEditedActivity.activityType_code == "recol" && this.activity_yield) {
          this.index = this.products.findIndex((element) => element.id == this.activity_yield.variety);
          if (this.index != -1) this.productSelected = this.products[this.index];
        }
      }
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

  },
  computed: {
    ...mapGetters({
      currentHarvest: "harvestStorage/currentEntry",
      currentEditedActivity: "activity/editedEntry",
      currentActivity: "activity/currentEntry",
      harvests: "harvestStorage/harvests",
      user: "user/user",
    }),
  },
  watch: {
    productSelected: {
      deep: true,
      handler: async function () {
        if (this.isOnYield)
          await this.$store.dispatch("yield/setEditedEntryValue", { variety: this.productSelected ? this.productSelected.id : null });
        else if (this.isOnHarvest || this.currentEditedActivity.activityType_code == "trans")
          await this.$store.dispatch("harvestStorage/setEditedEntryValue", { variety: this.productSelected ? this.productSelected.id : null });
        else if (this.currentEditedActivity.activityType_code == "recol")
          await this.$store.dispatch("activity/setEditedEntryValue", {theYield_variety: this.productSelected ? this.productSelected.id : null });
      },
    },
    currentEditedEntry: {
      deep: true,
      handler: function() {
        if((this.isOnHarvest || this.isOnYield) && this.currentEditedEntry !== undefined && this.currentEditedEntry.cropField !== undefined){
          let reloadProducts = false;
          if (this.cropField != this.currentEditedEntry.cropField) {
            this.cropField = this.currentEditedEntry.cropField;
            reloadProducts = true;
          }
          if (this.crop != this.currentEditedEntry.crop) {
            this.crop = this.currentEditedEntry.crop;
            reloadProducts = true;
          }
          if (this.crop_species_code != this.currentEditedEntry.crop_species_code) this.crop_species_code = this.currentEditedEntry.crop_species_code;
          if (reloadProducts) {
            // Le timer permet d'éviter un double rechargement de la liste des variétés,
            // lors d'un changement de parcelle conduisant directement à un changement de culture 
            if (this.timeOutId) clearTimeout(this.timeOutId);
            this.timeOutId = setTimeout(() => {
              this.loadProductOptions();
            }, 500) ;
          }
        }

      }
    },

    currentEditedActivity: {
      deep: true,
      handler: async function() {
        if (this.currentEditedActivity.startDate && this.doneDate != this.currentEditedActivity.startDate) {
          this.doneDate = this.currentEditedActivity.startDate;
          await this.$store.dispatch("harvestStorage/setEditedEntryValue", { doneDate: this.currentEditedActivity.startDate });
        }
        if (this.cropField != this.currentEditedActivity.cropField && this.currentEditedActivity.cropField !== undefined) {
          ("type of currentEditedActivity", this.currentEditedActivity.cropField ,typeof this.currentEditedActivity.cropField)
          this.cropField = this.currentEditedActivity.cropField;
          if(this.cropField !== undefined) {
            let cropField_obj = await this.$store.dispatch("cropfield/getOneCropfield", {id: this.cropField});
            this.crop = cropField_obj.crop;
            this.crop_species_code = cropField_obj.crop_species_code;
            await this.$store.dispatch("harvestStorage/setEditedEntryValue", { cropField: this.cropField });
            await this.$store.dispatch("harvestStorage/setEditedEntryValue", { crop: this.crop });
          }
          if (this.loadComponent)
            await this.loadProductOptions();
        }
      }
    },

  },
};
</script>

<style></style>
